<template>
  <div>
    <home-layout
      :leftBannerList="leftBannerList"
      :rightBannerList="rightBannerList"
      :navLeftList="navLeftList"
      :navRightList="navRightList"
      :hotList="hotList"
      :hotShowList="hotShowList"
      @preview="preview"
    ></home-layout>
  </div>
</template>

<script>
import homeLayout from "@/components/home/index.vue";
import { getBannerList } from "../../api/home";
import { getMainList } from "../../api/home";
import { brandsList } from "../../api/home";
export default {
  components: {
    homeLayout
  },
  data() {
    return {
      leftBannerList: [],
      rightBannerList: [
        {
          children: [
            { image: require("@/assets/images/home/banner_blue.png") },
            { image: require("@/assets/images/home/banner_green.png") }
          ]
        },
        {
          children: [
            { image: require("@/assets/images/home/banner_yellow.png") },
            { image: require("@/assets/images/home/banner_red.png") }
          ]
        }
      ],
      navLeftList: [],
      navRightList: [
        { image: require("@/assets/images/home/item1.png"), name: "手机端" },
        { image: require("@/assets/images/home/item2.png"), name: "我的设计" }
      ],
      hotList: [],
      hotShowList: []
    };
  },
  mounted() {
    this.getHomeList();
    this.getBannerList();
    this.getBrandsList();
  },
  methods: {
    preview(index, params) {
      console.log(index,params);
    },
    getHomeList(){
      getMainList({}).then(result => {
        this.navLeftList = result.categoryList;
        this.hotShowList = result.goodsList;
      })
      .catch(error => {
        console.log(JSON.stringify(error));
      });
    },
    getBannerList(){
      let dataform = {
        position: 1
      };
      getBannerList(dataform).then(result => {
        this.leftBannerList = result;
      })
      .catch(error => {
        console.log(JSON.stringify(error));
      });
    },
    getBrandsList(){
      brandsList().then(result => {
        console.log(JSON.stringify(result));
        this.hotList = result;
      })
      .catch(error => {
        console.log(JSON.stringify(error));
      });
    }
  }
};
</script>